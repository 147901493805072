body {
  font-family: "Spartan", sans-serif !important;

  background-color: #000000 !important;
}
* {
  box-sizing: border-box !important;
  margin: 0;
  padding: 0;
}
